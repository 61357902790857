import { ReactElement, useState } from 'react';
import { useNavigate } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Divider, SxProps, Theme } from '@mui/material';
import { useEnvironmentContext } from '../Contexts/EnvironmentProvider';
import { useNavigateAltSite } from '../Hooks/useNavigateAltSite';
import { useAuthContext } from '../Contexts/AuthProvider';
import { useMsal } from '@azure/msal-react';

interface Props {
  iconElement: ReactElement;
  sx?: SxProps;
  mobile: boolean;

  position?: 'left' | 'right';
  items: { path: string, label: string }[];
}

const NavMenu = ({ iconElement, mobile, position, items, sx }: Props) => {
  const { instance: msalInstance } = useMsal();
  const { user, signOut } = useAuthContext();

  const navigate = useNavigate();
  const navigateAlt = useNavigateAltSite();

  const { siteVariant, pageVariant } = useEnvironmentContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNavigationHandler = (url: string) => {
    return () => {
      handleClose();
      navigate(url);
    }
  }

  const handleNavigateAlt = () => {
    handleClose();
    navigateAlt('/');
  }

  const handleSignOut = async () => {
    handleClose();

    if (msalInstance.getActiveAccount()) {
      navigate('/auth/ms/logout');
    } else {
      await signOut();
      navigate('/', { replace: true });
    }
  }

  const menuItemStyle: SxProps<Theme> = mobile ?
    {}
    :
    { pl: '20px', pr: '50px', py: '10px', fontSize: '11pt' };

  return (
    <>
      <IconButton onClick={handleClick} sx={sx}>
        {iconElement}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: position ?? (mobile ? 'left' : 'right'),
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: position ?? (mobile ? 'left' : 'right'),
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          sx: { marginTop: '5px', width: (mobile ? '100%' : 'auto') }
        }}
      >
        {pageVariant === 'main' && user &&
          [
            <MenuItem key='menu-alt' onClick={handleNavigateAlt} sx={menuItemStyle}>My Podcast Page</MenuItem>,
            <Divider key='div-alt' />
          ]
        }
        {pageVariant === 'channel' && user &&
          [
            <MenuItem key='menu-alt' onClick={handleNavigateAlt} sx={menuItemStyle}>Back to Production Site</MenuItem>,
            <Divider key='div-alt' />
          ]
        }

        {items.map(x => (
          <MenuItem key={x.path} onClick={getNavigationHandler(x.path)} sx={menuItemStyle}>{x.label}</MenuItem>
        ))
        }

        {items.length > 0 && <Divider />}

        <MenuItem onClick={getNavigationHandler('/about')} sx={menuItemStyle}>About The Vactors Club</MenuItem>
        {siteVariant === 'default' &&
          <MenuItem onClick={getNavigationHandler('/membership')} sx={menuItemStyle}>Choose your membership</MenuItem>
        }
        <MenuItem onClick={getNavigationHandler('/guide')} sx={menuItemStyle}>Guide to create and upload a script</MenuItem>
        <MenuItem onClick={getNavigationHandler('/faq')} sx={menuItemStyle}>FAQ</MenuItem>
        <MenuItem onClick={getNavigationHandler('/glossary')} sx={menuItemStyle}>Glossary</MenuItem>
        <MenuItem onClick={getNavigationHandler('/contact')} sx={menuItemStyle}>Contact us</MenuItem>
        <MenuItem onClick={getNavigationHandler('/terms')} sx={menuItemStyle}>Terms of Service</MenuItem>
        <MenuItem onClick={getNavigationHandler('/privacy')} sx={menuItemStyle}>Privacy Policy</MenuItem>

        {user &&
          [
            <Divider key='div-signout' />,
            <MenuItem key='menu-signout' onClick={handleSignOut} sx={menuItemStyle}>Sign Out</MenuItem>
          ]
        }
      </Menu>
    </>
  );
}

export default NavMenu;