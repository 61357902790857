import { Link } from "react-router-dom";

import { useAuthContext } from "../Contexts/AuthProvider";

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from "@mui/material/Divider";
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';

import UserAvatar from '../UserAvatar';
import NavMenu from "./NavMenu";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useNavigateFrom from "../Hooks/useNavigateFrom";
import { useEnvironmentContext } from "../Contexts/EnvironmentProvider";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import User from "../interfaces/User";
import { useNavigateAltSite } from "../Hooks/useNavigateAltSite";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import DataArrayOutlinedIcon from '@mui/icons-material/DataArrayOutlined';
import LogoIcon from "../Icon/LogoIcon";

interface Props {
  selectedTab: string | null;

  onHome: () => void;
  onFavorite: () => void;
}
const Header = ({ selectedTab, onHome, onFavorite }: Props) => {
  const navigate = useNavigateFrom();
  const navigateAlt = useNavigateAltSite();

  const { user, signOut } = useAuthContext();
  const { siteVariant } = useEnvironmentContext();

  const [school, setSchool] = useState<string | undefined>();

  const theme = useTheme();

  const buttonStyle: React.CSSProperties = { color: theme.palette.text.primary, textTransform: 'none', fontSize: '10pt', whiteSpace: 'nowrap', minWidth: 'auto', alignSelf: 'flex-start' };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleSignOut = async () => {
    await signOut();
    navigateAlt('/');
  }
  useEffect(() => {
    let profileUrl = `/api/users/${user?.id}`;

    axios.get<User>(profileUrl)
      .then(res => {
        const userDetail = res.data;

        if (userDetail.classes && userDetail.classes.length > 0) {
          setSchool(userDetail.classes[0].school.name);
        }
      });
  }, []);

  const handleAvatarClick = () => {
    if (!user) return;

    if (user.handle)
      navigate(`/members/${user.handle}`);

    else navigate(`/profile/${user.id}`);
  }

  const getLogoSrc = () => {
    //    if (siteVariant === 'school') {
    //      return theme.palette.mode === 'dark' ? "/logo-school-white.png" : "/logo-school.png";
    //    } else {
    return theme.palette.mode === 'dark' ? "/logo-white.png" : "/logo.png";
    //    }
  }

  const getNavMenuItems = () => {
    let ret = [];

    if (user) {
      ret.push({ path: '/history', label: 'History' });
    }
    //    ret.push({ path: '/', label: 'Channels' });

    return ret;
  }

  if (!isMobile) {
    return (
      <Stack direction='column' sx={{
        background: 'lightgray',
        p: '15px'
      }}
        spacing='10px'
      >
        <Stack direction='row' alignItems='center' spacing='15px' mb='25px'>
          <UserAvatar user={user} sx={{
            width: '100px',
            height: '100px',
          }}
            onClick={handleSignOut}
          />
          <img style={{ height: '50px' }} onClick={() => navigate('/')} src='/logo-podcast.png' />
        </Stack>
        <Button sx={{ textTransform: 'none', alignSelf: 'flex-start' }} onClick={onHome}>
          <HomeOutlinedIcon fontSize="large" sx={{ color: selectedTab === 'home' ? 'red' : 'black' }} />
          <Typography color='black' pt='2px' ml='5px' fontSize='12pt'>Home</Typography>
        </Button>

        <Button sx={{ alignItems: 'center', textTransform: 'none', alignSelf: 'flex-start' }} onClick={onFavorite}>
          <FavoriteBorderOutlinedIcon fontSize="large" sx={{ color: selectedTab === 'favorite' ? 'red' : 'black' }} />
          <Typography color='black' pt='2px' ml='5px' fontSize='12pt'>Favorite podcasts</Typography>
        </Button>

        <div style={{ flexGrow: '1' }} />
        <Button sx={{ alignItems: 'center', textTransform: 'none', alignSelf: 'flex-start' }}
          onClick={() => navigateAlt('/')}
        >
          <LogoIcon sx={{ fontSize: '24pt', color: 'black' }} />
          <Typography color='black' pt='2px' ml='10px' fontSize='12pt'>Vactors web app</Typography>
        </Button>

        <Button sx={{ alignItems: 'center', textTransform: 'none', alignSelf: 'flex-start' }}
          onClick={handleSignOut}>
          <LogoutOutlinedIcon fontSize="large" sx={{ color: 'black' }} />
          <Typography color='black' pt='2px' ml='5px' fontSize='12pt'>Sign out</Typography>
        </Button>
      </Stack>
    )
  }

  return (
    <Box sx={{
      flexShrink: '0',
      flexBasis: { 'xs': '60px', 'md': '90px' },
      height: { 'xs': '60px', 'md': '90px' },
      backgroundColor: theme.palette.background.default,
      position: 'sticky', top: '0px',
      zIndex: 2
    }}>

      <Stack
        sx={{ my: '10px', height: '40px' }}
        direction='row'
        alignItems='center'
      >
        <img style={{ height: '30px', marginLeft: '15px' }} onClick={() => navigate('/')} src='/logo-podcast-white.png' />
        <IconButton sx={{ ml: '15px', my: '-8px', alignItems: 'center' }} onClick={onHome}>
          <HomeOutlinedIcon sx={{ fontSize: "30px", color: selectedTab === 'home' ? 'red' : 'white' }} />
        </IconButton>

        <IconButton sx={{ alignItems: 'center' }} onClick={onFavorite}>
          <FavoriteBorderOutlinedIcon sx={{ fontSize: "30px", color: selectedTab === 'favorite' ? 'red' : 'white' }} />
        </IconButton>

        <IconButton sx={{ ml: '25px' }}
          onClick={() => navigateAlt('/')}
        >
          <LogoIcon sx={{ color: 'white' }} />
        </IconButton>

        <IconButton sx={{ ml: '15px', my: '-8px' }}
          onClick={handleSignOut}>
          <LogoutOutlinedIcon sx={{ fontSize: "30px", color: 'white' }} />
        </IconButton>
        <div style={{ flex: '1' }} />
        {user &&
          <IconButton onClick={handleAvatarClick} sx={{ mr: '5px' }}>
            <UserAvatar user={user} sx={{
              width: '40px',
              height: '40px',
            }} />
          </IconButton>
        }
      </Stack>

      <Divider sx={{ background: '#fff' }} />
    </Box>
  );
}

export default Header;
