import { Button, Stack, SxProps, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const tabStyle: SxProps<Theme> = { color: 'white', textTransform: 'none', fontSize: { xs: '8pt', md: '10pt' }, whiteSpace: 'nowrap', minWidth: 'auto', borderRadius: '10px' };

  if (isMobile) {
    return (
      <Stack sx={{
        px: '10px', fontSize: '9pt', alignItems: 'center',
        background: '#631299'
      }} direction='row' spacing={1}>

        <img style={{ height: '15px' }} src='/logo2-white.png' />

        <div style={{ flex: '1' }} />

        <Button component={Link} to='/terms' sx={tabStyle}>Terms of Service</Button>
        <Button component={Link} to='/privacy' sx={tabStyle}>Privacy Policy</Button>
        <Button component={Link} to='/contact' sx={tabStyle}>Contact Us</Button>

        <div style={{ flex: '1' }} />
      </Stack>
    );
  }
  return (
    <Stack sx={{
      px: '10px', fontSize: '9pt', alignItems: 'center', position: 'sticky', bottom: '0px',
      background: '#631299'
    }} direction='row' spacing={1}>

      <img style={{ height: '20px' }} src='/logo-white.png' />

      <div style={{ flex: '1' }} />

      <Button component={Link} to='/terms' sx={tabStyle}>Terms of Service</Button>
      <Button component={Link} to='/privacy' sx={tabStyle}>Privacy Policy</Button>
      <Button component={Link} to='/contact' sx={tabStyle}>Contact Us</Button>

      <div style={{ flex: '1' }} />
      <Typography color='white' fontSize='10pt'>Copyright © 2024 Vactors</Typography>
    </Stack>
  );
}

export default Footer;
