import { SvgIcon } from "@mui/material";
import * as React from "react";

interface Props {
	fontSize?: 'inherit' | 'large' | 'medium' | 'small',
	sx?: any,
}
export default function LogoIcon(props: Props) {
	return (
		<SvgIcon
			version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
			{...props}
		>
			<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
				stroke="none">
				<path d="M0 2560 l0 -2560 550 0 549 0 3 208 3 207 -297 -3 -298 -3 0 2146 0
2146 298 -3 297 -3 -3 213 -3 212 -549 0 -550 0 0 -2560z"/>
				<path d="M4018 4908 l-3 -213 298 3 297 3 0 -2146 0 -2145 -295 0 -295 0 0
-205 0 -205 550 0 550 0 0 2560 0 2560 -550 0 -549 0 -3 -212z"/>
				<path d="M1405 3700 c-7 -11 5 -60 15 -60 4 0 24 -48 45 -107 21 -60 43 -117
50 -128 7 -11 29 -69 50 -130 22 -60 42 -112 46 -115 4 -3 23 -52 43 -110 20
-58 45 -121 55 -140 10 -19 33 -78 51 -130 18 -52 35 -97 39 -100 4 -3 22 -48
41 -100 18 -52 45 -122 60 -155 15 -33 40 -98 55 -145 15 -47 32 -87 36 -91 5
-3 25 -52 45 -109 20 -57 42 -113 49 -124 7 -12 30 -70 51 -131 21 -60 41
-112 44 -115 4 -3 24 -52 44 -110 20 -58 47 -124 59 -147 l22 -43 255 0 255 0
22 43 c12 23 39 89 59 147 20 58 39 107 43 110 4 3 24 55 45 115 21 61 45 121
52 135 8 14 30 70 50 125 19 55 39 102 43 105 4 3 23 50 41 105 18 55 41 116
51 135 10 19 35 82 55 140 20 58 40 107 44 110 3 3 21 48 40 100 18 52 41 111
51 130 10 19 34 82 55 140 20 58 40 107 43 110 4 3 24 55 45 115 21 61 42 115
47 120 4 6 26 60 47 122 22 61 48 122 57 137 36 54 28 56 -227 56 l-233 0 -21
-48 c-12 -26 -36 -92 -54 -147 -18 -55 -36 -102 -40 -105 -4 -3 -24 -57 -45
-120 -20 -63 -43 -124 -50 -135 -6 -11 -27 -65 -45 -120 -18 -55 -38 -109 -45
-120 -6 -11 -27 -65 -45 -120 -18 -55 -43 -122 -55 -150 -12 -27 -35 -88 -50
-135 -15 -47 -34 -96 -41 -110 -8 -14 -32 -79 -55 -145 -22 -66 -44 -122 -48
-125 -4 -3 -24 -58 -45 -122 -21 -65 -42 -118 -46 -118 -3 0 -24 53 -46 118
-21 64 -42 119 -45 122 -4 3 -26 59 -48 125 -23 66 -50 138 -60 160 -10 22
-28 72 -41 110 -12 39 -33 93 -45 120 -12 28 -37 95 -55 150 -18 55 -39 109
-45 120 -7 11 -27 65 -45 120 -18 55 -39 109 -45 120 -7 11 -30 72 -50 135
-21 63 -41 117 -45 120 -4 3 -22 50 -40 105 -18 55 -42 121 -54 147 l-21 48
-232 0 c-137 0 -234 -4 -238 -10z"/>
			</g>
		</SvgIcon>
	);
}